import { BlockConfig } from "@/options/blockOptions";
import { uuid } from "vue-uuid";
import moment from "moment";

type TextTypes = "normal" | "progress";

export interface TextConfig {
  textType: TextTypes;
  content: string;
}

export interface HistoryConfig {
  createdAt: number;
  content: string;
  items: Array<ItemConfig>;
}

export interface PostConfig {
  postId: string;
  createdAt: number;
  content: string;
}

export interface QuoteConfig {
  quoteId: string;
  content: string;
  createdAt: number;
  date?: QuoteDateConfig;
  range?: Array<number>;
}

export interface QuoteDateConfig {
  value: number;
  repeat: boolean;
}

export interface ItemConfig {
  itemId: string;
  content: string;
  done: boolean;
}

export interface TextBlockConfig extends BlockConfig, TextConfig {}

export interface CheckboxBlockConfig extends BlockConfig, TextConfig {
  items: Array<ItemConfig>;
  history: Array<HistoryConfig>;
  removeDone?: boolean;
}

export interface QuotesBlockConfig extends BlockConfig {
  quotes: Array<QuoteConfig>;
}

export interface JournalBlockConfig extends BlockConfig {
  template: string;
  content: string;
  posts: Array<PostConfig>;
}

export const todaysPrioritiesItems = [
  {
    itemId: uuid.v4(),
    content: "Mindfulness",
    done: false
  } as ItemConfig,
  {
    itemId: uuid.v4(),
    content: "Be thankful",
    done: false
  } as ItemConfig,
  {
    itemId: uuid.v4(),
    content: "Finish tasks from work",
    done: false
  } as ItemConfig
];

export const mydailyRoutineItems = [
  {
    itemId: uuid.v4(),
    content: "Meditate for at least ... min",
    done: false
  } as ItemConfig,
  {
    itemId: uuid.v4(),
    content: "Exercise for .... min",
    done: false
  } as ItemConfig,
  {
    itemId: uuid.v4(),
    content: "Read for .... min",
    done: false
  } as ItemConfig,
  {
    itemId: uuid.v4(),
    content: "Visualize for ... min",
    done: false
  } as ItemConfig,
  {
    itemId: uuid.v4(),
    content: "Write in my daily journal",
    done: false
  } as ItemConfig
];

export const myVisualizationTitle = `
<h2>My dream visualization:</h2>
`;
export const missionStatementContent = `
  <p>The purpose of my life is to <strong>.........</strong> in order to <strong>.........</strong></p>
`;
export const missionStatementTitle = `
  <h2>My mission statement:</h2>
`;
export const myValuesContent = `
  <ul><li><p>Love</p></li><li><p>Freedom</p></li><li><p>Growth</p></li><li><p>Health</p></li><li><p>Honesty</p></li></ul>
`;
export const myValuesTitle = `
  <h2>My values:</h2>
`;
export const myYearGoalaContent = `
  <ul data-type="todo_list"><li data-type="todo_item" data-done="false" data-item-id="8c81e83b-d807-4332-9da8-7bf587bfa426"><span class="todo-checkbox" contenteditable="false"></span><div class="todo-content"><p style="text-align: left">Travel to ... new countries</p></div></li><li data-type="todo_item" data-done="false" data-item-id="07f4fc67-2add-4067-86c5-3c374dd94985"><span class="todo-checkbox" contenteditable="false"></span><div class="todo-content"><p style="text-align: left">Add ... revenue streams</p></div></li><li data-type="todo_item" data-done="false" data-item-id="1a20eb5d-3769-4535-b2f3-cd6548a0576d"><span class="todo-checkbox" contenteditable="false"></span><div class="todo-content"><p style="text-align: left">Get a promotion</p></div></li><li data-type="todo_item" data-done="false" data-item-id="ed5e4377-69c6-41fe-a435-3098e87984dd"><span class="todo-checkbox" contenteditable="false"></span><div class="todo-content"><p style="text-align: left">Meet ... new people</p></div></li></ul>
`;
export const myYearGoalsTitle = `
  <h2>My year goals:</h2>
`;
export const mydailyRoutineTitle = `
  <h2>My daily routine:</h2>
`;
export const todaysPrioritiesTitle = `
  <h2>Today's priorities:</h2>
`;
export const myRelationshipVisionContent = `
  <p>My relationship is built on partnership. We help each other grow – we work on each other's soft and hard skills.</p>
  <p>We celebrate our wins and actively try to look for solutions to the challenges we encounter.</p>
`;
export const myRelationshipVisionTitle = `
  <h2>My relationship vision:</h2>
`;

export const dailyQuotes = [
  {
    quoteId: uuid.v4(),
    content: `
      <p style="text-align: center"><em><strong>"As we are liberated from our own fear, our presence automatically liberates others."</strong></em></p>
      <p style="text-align: center">by Marianne Williamson</p>
    `,
    createdAt: moment().unix()
  } as QuoteConfig
];

export const newDailyQuotes = [
  {
    quoteId: uuid.v4(),
    content: `
      <p style="text-align: center"><em><strong>"You are what you do, not what you say you'll do."</strong></em></p>
      <p style="text-align: center">by Carl Gustav Jung</p>
    `,
    createdAt: moment().unix()
  } as QuoteConfig,
  {
    quoteId: uuid.v4(),
    content: `
      <p style="text-align: center"><em><strong>"Don't let the fear of the time it will take to accomplish something stand in the way of your doing it. The time will pass anyway; we might just as well put that passing time to the best possible use."</strong></em></p>
      <p style="text-align: center">by Earl Nightingale</p>
    `,
    createdAt: moment().unix()
  } as QuoteConfig,
  {
    quoteId: uuid.v4(),
    content: `
      <p style="text-align: center"><em><strong>"What you do makes a difference, and you have to decide what kind of difference you want to make."</strong></em></p>
      <p style="text-align: center">by Jane Goodall</p>
    `,
    createdAt: moment().unix()
  } as QuoteConfig,
  {
    quoteId: uuid.v4(),
    content: `
      <p style="text-align: center"><em><strong>"Don't go through life, grow through life."</strong></em></p>
      <p style="text-align: center">by Eric Butterworth</p>
    `,
    createdAt: moment().unix()
  } as QuoteConfig,
  {
    quoteId: uuid.v4(),
    content: `
      <p style="text-align: center"><em><strong>"Remember that wherever your heart is, there you will find your treasure."</strong></em></p>
      <p style="text-align: center">by Paulo Coelho</p>
    `,
    createdAt: moment().unix()
  } as QuoteConfig,
  {
    quoteId: uuid.v4(),
    content: `
      <p style="text-align: center"><em><strong>"Recognizing that you are not where you want to be is a starting point to begin changing your life."</strong></em></p>
      <p style="text-align: center">by Deborah Day</p>
    `,
    createdAt: moment().unix()
  } as QuoteConfig,
  {
    quoteId: uuid.v4(),
    content: `
      <p style="text-align: center"><em><strong>"Incredible change happens in your life when you decide to take control of what you have power over instead of craving control over what you don't."</strong></em></p>
      <p style="text-align: center">by Steve Maraboli</p>
    `,
    createdAt: moment().unix()
  } as QuoteConfig,
  {
    quoteId: uuid.v4(),
    content: `
      <p style="text-align: center"><em><strong>"Be patient with yourself. You are growing stronger every day. The weight of the world will become lighter...and you will begin to shine brighter. Don't give up."</strong></em></p>
      <p style="text-align: center">by Robert Tew</p>
    `,
    createdAt: moment().unix()
  } as QuoteConfig,
  {
    quoteId: uuid.v4(),
    content: `
      <p style="text-align: center"><em><strong>"The will to win, the desire to succeed, the urge to reach your full potential...these are the keys that will unlock the door to personal excellence."</strong></em></p>
      <p style="text-align: center">by Confucius</p>
    `,
    createdAt: moment().unix()
  } as QuoteConfig,
  {
    quoteId: uuid.v4(),
    content: `
      <p style="text-align: center"><em><strong>"Be patient with yourself. Self-growth is tender; it's holy ground. There's no greater investment."</strong></em></p>
      <p style="text-align: center">by Stephen Covey</p>
    `,
    createdAt: moment().unix()
  } as QuoteConfig,
  {
    quoteId: uuid.v4(),
    content: `
      <p style="text-align: center"><em><strong>"The power of imagination makes us infinite."</strong></em></p>
      <p style="text-align: center">by John Muir</p>
    `,
    createdAt: moment().unix()
  } as QuoteConfig,
  {
    quoteId: uuid.v4(),
    content: `
      <p style="text-align: center"><em><strong>"With self-discipline most anything is possible."</strong></em></p>
      <p style="text-align: center">by Theodore Roosevelt</p>
    `,
    createdAt: moment().unix()
  } as QuoteConfig,
  {
    quoteId: uuid.v4(),
    content: `
      <p style="text-align: center"><em><strong>"Accept responsibility for your life. Know that it is you who will get you where you want to go, no one else."</strong></em></p>
      <p style="text-align: center">by Les Brown</p>
    `,
    createdAt: moment().unix()
  } as QuoteConfig,
  {
    quoteId: uuid.v4(),
    content: `
      <p style="text-align: center"><em><strong>"Believe in your infinite potential. Your only limitations are those you set upon yourself."</strong></em></p>
      <p style="text-align: center">by Roy T. Bennett</p>
    `,
    createdAt: moment().unix()
  } as QuoteConfig,
  {
    quoteId: uuid.v4(),
    content: `
      <p style="text-align: center"><em><strong>"Believe in yourself, your abilities and your own potential. Never let self-doubt hold you captive. You are worthy of all that you dream of and hope for."</strong></em></p>
      <p style="text-align: center">by Roy T. Bennett</p>
    `,
    createdAt: moment().unix()
  } as QuoteConfig,
  {
    quoteId: uuid.v4(),
    content: `
      <p style="text-align: center"><em><strong>"Reality is a projection of your thoughts or the things you habitually think about."</strong></em></p>
      <p style="text-align: center">by Stephen Richards</p>
    `,
    createdAt: moment().unix()
  } as QuoteConfig,
  {
    quoteId: uuid.v4(),
    content: `
      <p style="text-align: center"><em><strong>"Yesterday I was clever, so I wanted to change the world. Today I am wise, so I am changing myself."</strong></em></p>
      <p style="text-align: center">by Rumi</p>
    `,
    createdAt: moment().unix()
  } as QuoteConfig,
  {
    quoteId: uuid.v4(),
    content: `
      <p style="text-align: center"><em><strong>"Knowing yourself is the beginning of all wisdom."</strong></em></p>
      <p style="text-align: center">by Aristotle</p>
    `,
    createdAt: moment().unix()
  } as QuoteConfig,
  {
    quoteId: uuid.v4(),
    content: `
      <p style="text-align: center"><em><strong>"I can choose either to be a victim of the world or an adventurer in search of treasure. It's all a question of how I view my life."</strong></em></p>
      <p style="text-align: center">by Paulo Coelho</p>
    `,
    createdAt: moment().unix()
  } as QuoteConfig,
  {
    quoteId: uuid.v4(),
    content: `
      <p style="text-align: center"><em><strong>"Instead of worrying about what you cannot control, shift your energy to what you can create."</strong></em></p>
      <p style="text-align: center">by Roy T. Bennett</p>
    `,
    createdAt: moment().unix()
  } as QuoteConfig
  // EXAMPLES: *add author and title into content
  // {
  //   quoteId: uuid.v4(),
  //   content: `<p><em>"This quote will show between 26.11.2020-31.12.2020"</em></p>`,
  //   createdAt: moment().unix(),
  //   range: [moment("2020-11-26").unix(), moment("2020-12-31").unix()]
  // } as QuoteConfig,
  // {
  //   quoteId: uuid.v4(),
  //   content: `<p><em>"This quote will show every Monday (starting 16.11.2020)"</em></p>`,
  //   createdAt: moment().unix(),
  //   date: {
  //     value: moment("2020-11-16").unix(),
  //     repeat: true
  //   } as QuoteDateConfig
  // } as QuoteConfig,
  // {
  //   quoteId: uuid.v4(),
  //   content: `<p><em>"This quote will show every Tuesday (starting 17.11.2020)"</em></p>`,
  //   createdAt: moment().unix(),
  //   date: {
  //     value: moment("2020-11-17").unix(),
  //     repeat: true
  //   } as QuoteDateConfig
  // } as QuoteConfig,
  // {
  //   quoteId: uuid.v4(),
  //   content: `<p><em>"This quote will show every Wednesday (starting 18.11.2020)"</em></p>`,
  //   createdAt: moment().unix(),
  //   date: {
  //     value: moment("2020-11-18").unix(),
  //     repeat: true
  //   } as QuoteDateConfig
  // } as QuoteConfig,
  // {
  //   quoteId: uuid.v4(),
  //   content: `<p><em>"This quote will show every Thursday (starting 19.11.2020)"</em></p>`,
  //   createdAt: moment().unix(),
  //   date: {
  //     value: moment("2020-11-19").unix(),
  //     repeat: true
  //   } as QuoteDateConfig
  // } as QuoteConfig,
  // {
  //   quoteId: uuid.v4(),
  //   content: `<p><em>"This quote will show every Friday (starting 20.11.2020)"</em></p>`,
  //   createdAt: moment().unix(),
  //   date: {
  //     value: moment("2020-11-20").unix(),
  //     repeat: true
  //   } as QuoteDateConfig
  // } as QuoteConfig,
  // {
  //   quoteId: uuid.v4(),
  //   content: `<p><em>"This quote will show every Saturday (starting 21.11.2020)"</em></p>`,
  //   createdAt: moment().unix(),
  //   date: {
  //     value: moment("2020-11-21").unix(),
  //     repeat: true
  //   } as QuoteDateConfig
  // } as QuoteConfig,
  // {
  //   quoteId: uuid.v4(),
  //   content: `<p><em>"This quote will show every Sunday (starting 22.11.2020)"</em></p>`,
  //   createdAt: moment().unix(),
  //   date: {
  //     value: moment("2020-11-22").unix(),
  //     repeat: true
  //   } as QuoteDateConfig
  // } as QuoteConfig,
  // {
  //   quoteId: uuid.v4(),
  //   content: `<p><em>"This quote will show on 26.11.2020"</em></p>`,
  //   createdAt: moment().unix(),
  //   date: {
  //     value: moment("2020-11-26").unix(),
  //     repeat: false
  //   } as QuoteDateConfig
  // } as QuoteConfig
];
export const dailyQuotesTitle = `
  <h2>Daily quotes:</h2>
`;

export const dailyJournalContent = `
  <p>What did I experience today that I would like to remember?</p>
  <p><strong>.........</strong></p>
  <p>What will I keep doing, stop doing and start doing?</p>
  <p><strong>.........</strong></p>
  <p>What am I grateful for today?</p>
  <p><strong>.........</strong></p>
`;
export const dailyJournalTitle = `
  <h2>Daily journal:</h2>
`;

export const maxBackgroundImageSizeMB = 20;

export const targetBackgroundImageSizeMB = 2;
